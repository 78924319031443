<template>
  <h1 v-if="null !== isValidSessionToken && 0 === Number(isValidSessionToken)">Sorry, you do not have access to this.</h1>
</template>

<script>
import {APP_ENV} from "../../.env";

export default {
  name: 'NotLogged',
  watch: {
    isValidSessionToken: {
      handler () {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const asid = parameters.get('asid');
        const auid = parameters.get('auid');
        if (null !== this.isValidSessionToken && 1 === Number(this.isValidSessionToken) && null !== asid && null !== auid && 'development' !== APP_ENV) {
          this.$router.push({ name: 'Home', query: { asid, auid } })
        }
      },
      immediate: true
    }
  },
  mounted () {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const asid = parameters.get('asid');
    const auid = parameters.get('auid');

    if ((false === this.isValidSessionToken || 0 === Number(this.isValidSessionToken) || (null !== asid && null !== auid) )&& 'development' !== APP_ENV)
      this.getSessionToken(asid, auid)
  },
  computed: {
    isValidSessionToken () {
      return this.$store.getters['auth/getSessionTokenStatus']
    }
  },
  methods: {
    getSessionToken (asid, auid) {
      this.$store.commit('setLoader', true)
      this.$store.dispatch('auth/getSessionTokenStatus', { asid, auid })
        .then((res) => {
          if (0 === res.isValid) {
            this.$router.push({ name: 'NotLogged' })
          }
          this.$store.commit('setLoader', false)
        })
        .catch(() => {
          this.$router.push({ name: 'NotLogged' })
          this.$store.commit('setLoader', false)
        })
    }
  }
}
</script>

<style scoped>

</style>
